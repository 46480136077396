/* src/view/protected/AssignTaskModal.css */
.assign-task-modal .modal-dialog {
    max-width: 600px;
  }
  
  .task-list {
    margin-top: 15px;
    font-size: 0.9rem;
    max-height: 250px; /* Limit height to show only three tasks before scrolling */
    overflow-y: auto;
  }
  
  .task-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px;
    margin-bottom: 5px;
    border-radius: 4px;
  }
  
  .task-title {
    flex: 2;
  }
  
  .task-due-date {
    flex: 1;
    text-align: center;
  }
  
  .task-status {
    flex: 1;
    text-align: center;
  }
  
  .task-row button {
    flex: 0 0 auto;
  }
  