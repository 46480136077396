/* AddMemberForm.css */
.country-code-wrapper {
    display: flex;
    align-items: center;
    position: relative;
  }
  
  .country-code-dropdown {
    opacity: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 1;
  }
  
  .selected-code {
    padding: 0.375rem 0.75rem;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
    background-color: #fff;
    pointer-events: none;
    z-index: 0;
    width: 80px; /* Adjust the width as needed */
    text-align: center;
  }
  
  @media (max-width: 768px) {
    .selected-code {
      width: 60px; /* Adjust the width for mobile devices */
    }
  }
  
  .d-flex {
    display: flex;
    align-items: center;
  }
  