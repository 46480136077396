/* src/Dashboard.css */
.dashboard-container {
    background: #f4f4f9;
    padding: 20px;
    border-radius: 15px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .header-container {
    height: 80px;
    background-image: linear-gradient(to right, #ccb3a1, #87664e);
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .dashboard-title {
    color: #fff;
    font-size: 24px;
    font-weight: bold;
  }
  