/* src/components/ProfileCard.css */
.profile-card {
  position: relative;
  border: 2px solid #ccc;
  border-radius: 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden; /* Ensures the pseudo-element doesn't overflow the card */
}

.profile-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./../public/assets/img/felsiselam_logo.jpg'); /* Adjusted path */
  background-repeat: repeat;
  background-size: 10%;
  opacity: 0.5; /* Adjust the opacity to make the watermark lighter */
  z-index: 1;
}

.profile-card .card-body {
  position: relative;
  padding: 30px;
  z-index: 2; /* Ensures the content is above the pseudo-element */
  background-color: rgba(255, 255, 255, 0.8); /* Optional: Adds a slight background to ensure text readability */
}

.profile-card .card-body img {
  border: 2px solid #ddd;
  /* padding: 5px; */
  background-color: #fff;
  border-radius: 50%;
}

/* .profile-card .card-body h3 {
  margin-top: 5px;
  font-size: 20px;
  font-weight: bold;
} */

/* .profile-card .card-body p {
  margin: 5px 0;
} */
