/* src/PressRelease.css */

.press-release-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 20px;
  }
  
  .press-release-title {
    font-size: 2rem;
    margin-bottom: 20px;
  }
  
  .vertical-slideshow-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 900px;
  }
  
  .vertical-image-container {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .vertical-slideshow-image {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.5s ease-in-out;
  }
  
  .vertical-slideshow-image:hover {
    transform: scale(1.05);  /* Slight zoom effect on hover */
  }
  
  /* Add some responsive behavior for smaller screens */
  @media (max-width: 768px) {
    .vertical-slideshow-image {
      width: 100%;
    }
  
    .press-release-title {
      font-size: 1.5rem;
    }
  }
  